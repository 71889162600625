(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/accountservices/deposit-restriction/assets/javascripts/react/components/age-check.js') >= 0) return;  svs.modules.push('/components/accountservices/deposit-restriction/assets/javascripts/react/components/age-check.js');
'use strict';

const {
  useState,
  useEffect,
  useRef
} = React;
const {
  Form
} = svs.ui.reactForm;
const {
  ReactButton: Button,
  ReactIcon: Icon
} = svs.ui;
const {
  data,
  RadioButtons,
  DepositRestrictionDialogs,
  DepositRestriction
} = svs.accountservices.deposit_restriction;
const AgeCheck = () => {
  const [numberOfDeposits, setNumberOfDeposits] = useState(null);
  const restrictions = useRef(null);
  useEffect(() => {
    if (!restrictions.current) {
      restrictions.current = new DepositRestriction();
    } else {
      restrictions.current.reloadElements();
    }
  }, []);
  const handleSubmit = e => {
    e.preventDefault();
    DepositRestrictionDialogs.showSetDepositLimitsDialog(numberOfDeposits, data === null || data === void 0 ? void 0 : data.isPnp);
  };
  return React.createElement("section", {
    className: "align-center padding-1"
  }, React.createElement("div", {
    className: "panel-wrapper js-deposit-restriction bg-white margin-top-2 inline-block"
  }, React.createElement(Icon, {
    icon: "deposit-limits",
    size: "700"
  }), React.createElement("h2", {
    className: "margin-top-3 f-550"
  }, "T\xE4nk p\xE5 att du redan har satt in pengar flera g\xE5nger idag - genomsnittet \xE4r en g\xE5ng."), React.createElement(Form, {
    id: "js-deposit-restriction-form",
    onSubmit: handleSubmit
  }, React.createElement("fieldset", null, React.createElement("legend", {
    className: "js-form-legend-deposit-restriction w100 margin-top-3 margin-bottom-3"
  }, "Hur m\xE5nga fler g\xE5nger vill du kunna s\xE4tta in", React.createElement("br", null), "pengar idag?"), React.createElement(RadioButtons, {
    isAdult: data === null || data === void 0 ? void 0 : data.isAdult,
    isPnp: data === null || data === void 0 ? void 0 : data.isPnp,
    selectedValue: numberOfDeposits,
    setNumberOfDeposits: setNumberOfDeposits
  }), React.createElement(Button, {
    block: true,
    className: "deposit-restriction-submit js-deposit-restriction-submit margin-top-2 margin-bottom-2",
    disabled: numberOfDeposits === null,
    focus: true,
    size: 300,
    type: "submit"
  }, "Spara")))));
};
svs.accountservices.deposit_restriction.AgeCheck = AgeCheck;

 })(window);