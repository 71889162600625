(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/accountservices/deposit-restriction/assets/javascripts/restriction.js') >= 0) return;  svs.modules.push('/components/accountservices/deposit-restriction/assets/javascripts/restriction.js');

'use strict';

const {
  data
} = svs.accountservices.deposit_restriction;
const {
  optionsForAge
} = svs.accountservices.deposit_restriction.AgeGroups;
class DepositRestriction {
  constructor() {
    const form = document.getElementById('js-deposit-restriction-form');
    if (form) {
      this.selectedNumberOfDeposits = false;
      this.radioButtons = document.querySelectorAll('.js-deposit-restriction-radio-button');
      this.legend = document.querySelector('.js-form-legend-deposit-restriction');
      this.radioButtons.forEach(radioButton => {
        radioButton.addEventListener('click', e => {
          this.sendRestrictionClick(e);
        });
      });
      svs.accountservices.playscanLog.postToPlayscan({
        intent: 'setdepositslimit',
        page: 'deposits',
        payload: {
          shown: {
            options: data.isAdult ? optionsForAge.overTwentyFive : optionsForAge.underTwentyFive,
            text: this.legend.innerText
          }
        },
        session_id: 'deposits',
        site: 'default'
      });
    }
  }
  reloadElements() {
    const form = document.getElementById('js-deposit-restriction-form');
    if (form) {
      this.selectedNumberOfDeposits = false;
      this.radioButtons = document.querySelectorAll('.js-deposit-restriction .radio-button');
      this.legend = document.querySelector('.js-form-legend-deposit-restriction');
      this.radioButtons.forEach(radioButton => {
        radioButton.addEventListener('click', e => {
          this.sendRestrictionClick(e);
        });
      });
    }
  }
  sendRestrictionClick(e) {
    var _e$target;
    const numDeposits = (_e$target = e.target) === null || _e$target === void 0 ? void 0 : _e$target.value;
    if (numDeposits !== this.selectedNumberOfDeposits) {
      this.selectedNumberOfDeposits = numDeposits;
      svs.accountservices.playscanLog.postToPlayscan({
        intent: 'setdepositslimit',
        page: 'deposits',
        payload: {
          clicked: numDeposits
        },
        session_id: 'deposits',
        site: 'default'
      });
    }
  }
}


svs.accountservices = svs.accountservices || {};
svs.accountservices.deposit_restriction = svs.accountservices.deposit_restriction || {};
svs.accountservices.deposit_restriction.DepositRestriction = DepositRestriction;

 })(window);