(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/accountservices/deposit-restriction/assets/javascripts/react/components/radio-buttons.js') >= 0) return;  svs.modules.push('/components/accountservices/deposit-restriction/assets/javascripts/react/components/radio-buttons.js');
'use strict';

const {
  RadioGroup,
  RadioButton
} = svs.ui.reactForm;
const {
  underTwentyFive,
  overTwentyFive
} = svs.accountservices.deposit_restriction.AgeGroups;
const RadioButtons = _ref => {
  let {
    isAdult,
    setNumberOfDeposits,
    numberOfDeposits,
    isPnp
  } = _ref;
  const buttons = isAdult ? overTwentyFive : underTwentyFive;
  return React.createElement(RadioGroup, {
    className: isPnp ? 'pnp-class' : '',
    multipleLines: true,
    size: 200
  }, buttons.map(button => React.createElement(RadioButton, {
    className: "js-deposit-restriction-radio-button",
    key: button.value,
    minWidth: button.minWidth,
    name: "toggle-radio-group-size-100[]",
    onChange: () => setNumberOfDeposits(button.value),
    selected: numberOfDeposits === button.value,
    type: "radio",
    value: button.value
  }, button.label)));
};
svs.accountservices.deposit_restriction.RadioButtons = RadioButtons;

 })(window);