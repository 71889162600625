(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/accountservices/deposit-restriction/assets/javascripts/dialogs.js') >= 0) return;  svs.modules.push('/components/accountservices/deposit-restriction/assets/javascripts/dialogs.js');

'use strict';

function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
class DepositRestrictionDialogs {
  static showConfirmLimitsDialog(numberOfDeposits) {
    let navigateTo = svs.core.urlMapping.get('transactionsHome');
    let title = "Du har valt att kunna s\xE4tta in pengar ".concat(numberOfDeposits, " ").concat(numberOfDeposits > 1 ? 'gånger' : 'gång', " till idag.");
    if (numberOfDeposits === 0) {
      title = 'Du har valt att begränsa dig från att sätta in mer pengar idag.';
    }
    svs.ui.dialog.api.add(new svs.ui.dialog.Confirm({
      branding: svs.ui.dialog.branding[svs.components.lbUtils.branding],
      title,
      icon: 'deposit-limits',
      area: svs.ui.dialog.area.POPUP,
      type: svs.ui.dialog.type.CONFIRM,
      message: [{
        type: svs.ui.dialog.message.TEXT,
        text: ''
      }],
      actions: [{
        title: 'Okej',
        callback: () => {
          var _svs$utils$url$queryS, _svs$accountservices$;
          let returnUrl = (_svs$utils$url$queryS = svs.utils.url.queryString) === null || _svs$utils$url$queryS === void 0 ? void 0 : _svs$utils$url$queryS.returnUrl;
          const group = (_svs$accountservices$ = svs.accountservices.deposit_restriction) === null || _svs$accountservices$ === void 0 || (_svs$accountservices$ = _svs$accountservices$.data) === null || _svs$accountservices$ === void 0 ? void 0 : _svs$accountservices$.group;
          if (group && group === 'after' && returnUrl) {
            svs.utils.url.navigate(svs.utils.url.makeInternal(returnUrl));
            return false;
          }
          if (numberOfDeposits === 0) {
            svs.utils.url.navigate('/');
            return false;
          }
          returnUrl = returnUrl ? "?returnUrl=".concat(svs.utils.url.makeInternal(returnUrl)) : '';
          if (svs.player_deposit.data.isFromQuickDeposit) {
            navigateTo = svs.core.urlMapping.get('quickDeposit');
          }
          svs.utils.url.navigate("".concat(navigateTo).concat(returnUrl));
        }
      }]
    }));
    svs.accountservices.playscanLog.postToPlayscan({
      intent: 'confirmdepositslimit',
      page: 'deposits',
      payload: {
        shown: {
          options: 'ok',
          text: title
        }
      },
      ready_only: '0',
      session_id: 'deposits'
    });
  }
  static showSetDepositLimitsDialog(numberOfDeposits) {
    let title = "Vill du kunna s\xE4tta in pengar ".concat(numberOfDeposits, " ").concat(numberOfDeposits > 1 ? 'gånger' : 'gång', " till idag?");
    if (numberOfDeposits === 0) {
      title = 'Vill du begränsa dig från att sätta in mer pengar idag?';
    }
    const dialog = new svs.ui.dialog.Confirm({
      branding: svs.ui.dialog.branding[svs.components.lbUtils.branding],
      title,
      icon: 'deposit-limits',
      area: svs.ui.dialog.area.POPUP,
      type: svs.ui.dialog.type.CONFIRM,
      message: [{
        type: svs.ui.dialog.message.TEXT,
        text: ''
      }],
      actions: [{
        title: 'Avbryt',
        callback: () => {
          dialog.close();
          svs.accountservices.playscanLog.postToPlayscan({
            intent: 'confirmdepositslimit',
            page: 'deposits',
            payload: {
              clicked: 'cancel'
            },
            ready_only: '0',
            session_id: 'deposits'
          });
        }
      }, {
        title: 'Bekräfta',
        showLoader: true,
        callback: async () => {
          const postOk = await svs.accountservices.playscanLog.postToPlayscan({
            intent: 'confirmdepositslimit',
            page: 'deposits',
            payload: {
              confirmed: numberOfDeposits
            },
            ready_only: '0',
            session_id: 'deposits'
          });
          dialog.close();
          if (postOk) {
            DepositRestrictionDialogs.showConfirmLimitsDialog(numberOfDeposits);
          } else {
            DepositRestrictionDialogs.showGenericErrorDialog();
          }
        }
      }]
    });
    svs.accountservices.playscanLog.postToPlayscan({
      intent: 'confirmdepositslimit',
      page: 'deposits',
      payload: {
        shown: {
          options: 'cancel-confirm',
          text: title
        }
      },
      ready_only: '0',
      session_id: 'deposits'
    });
    svs.ui.dialog.api.add(dialog);
  }
  static async showNotAllowToDepositDialog() {
    const dialog = new svs.ui.dialog.Confirm({
      branding: svs.ui.dialog.branding[svs.components.lbUtils.branding],
      title: 'Du har valt att inte kunna sätta in pengar fler gånger idag',
      icon: 'deposit-limits',
      area: svs.ui.dialog.area.POPUP,
      type: svs.ui.dialog.type.CONFIRM,
      message: [{
        type: svs.ui.dialog.message.TEXT,
        text: 'Du kan tidigast sätta in pengar imorgon.'
      }],
      actions: [{
        title: 'Okej',
        callback: async () => {
          dialog.close();
          svs.ui.loader.show();
          svs.utils.url.navigate('/');
        }
      }]
    });
    await svs.accountservices.playscanLog.postToPlayscan({
      intent: 'depositslimithit',
      page: 'deposits',
      payload: {
        shown: {
          options: 'ok',
          text: 'Du har valt att inte kunna sätta in pengar fler gånger idag\nDu kan tidigast sätta in pengar imorgon.'
        }
      },
      ready_only: '0',
      session_id: 'deposits'
    });
    svs.ui.dialog.api.add(dialog);
  }
  static async logNotAllowToDepositPage(text) {
    await svs.accountservices.playscanLog.postToPlayscan({
      intent: 'depositslimithit',
      page: 'deposits',
      payload: {
        shown: {
          text
        }
      },
      ready_only: '0',
      session_id: 'deposits'
    });
  }
}
_defineProperty(DepositRestrictionDialogs, "showGenericErrorDialog", () => {
  svs.ui.dialog.api.add(new svs.ui.dialog.Confirm({
    branding: svs.ui.dialog.branding[svs.components.lbUtils.branding],
    type: svs.ui.dialog.type.ERROR,
    title: 'Något gick fel',
    message: [{
      text: 'Vänligen försök igen om en liten stund.',
      type: svs.ui.dialog.message.TEXT
    }],
    actions: [{
      title: 'Ok'
    }]
  }));
});
svs.accountservices.deposit_restriction.DepositRestrictionDialogs = DepositRestrictionDialogs;

 })(window);